import { render, staticRenderFns } from "./Products.template.vue?vue&type=template&id=05ac4e8c&scoped=true&"
var script = {}
import style0 from "./Products.template.vue?vue&type=style&index=0&id=05ac4e8c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05ac4e8c",
  null
  
)

export default component.exports